/* This element, GameRow should display a game and a player's pick, if any. 
It should allow the user to change their pick, unless disabled (after the game start).
It should display the two teams on either side, as well as the line for the game, and be user friendly. */
import React, {useContext, useState} from 'react'
import { Box, VStack, HStack, Text, Button, Checkbox, useMediaQuery, useColorModeValue, Image, Flex} from '@chakra-ui/react'
import { PickemContext } from '../handlers/context/PickemContext';
import dh from '../calc/dates.js';
import sh from '../calc/scoring.js';


const GameRow = ({game, picks, addPick, removePick, toggleKeyGame, isPickDisabled, isKeyGameDisabled}) => {
    const { state, dispatch } = useContext(PickemContext);
    const [myPick, setMyPick] = useState(null);
    const [myKey, setMyKey] = useState(null);
    const [isMobile, setIsMobile] = useState(useMediaQuery('(max-width: 600px)')[0]);
    const bgColor = useColorModeValue('forwardWhite.50', 'forwardBlue.700');
    const bgContrastColor = useColorModeValue('forwardWhite.500', 'forwardBlue.900');
    const pickBorderColor = useColorModeValue('green.600', 'green.800');
    const textColor = useColorModeValue('black', 'white');
    const fullBonusColor = useColorModeValue('green.300', 'green.600');
    const halfBonusColor = useColorModeValue('yellow.500', 'yellow.700');

    const is_game_started = dh.convertESTtoUTC(game.game_time) < new Date();
    const is_home_pick = picks.find((pick) => pick.pick === game.home_team);
    const is_away_pick = picks.find((pick) => pick.pick === game.away_team);
    const is_game_scored = game.home_points !== null && game.away_points !== null;

    const teams = state?.teams;

    const home_team = teams?.find((team) => team.team_id === game.home_team);
    const away_team = teams?.find((team) => team.team_id === game.away_team);



    let my_pick;
    if(is_home_pick) {
        my_pick = is_home_pick;
    }
    else if(is_away_pick) {
        my_pick = is_away_pick;
    }

    const is_home_key = is_home_pick?.is_key;
    const is_away_key = is_away_pick?.is_key;

    const my_config = state?.event?.configuration;
    const is_disabled = false;

    const is_correct_pick = sh.calc_correct_picks(my_pick, game);
    const pick_color = 
            is_correct_pick===1 ? 'green.500' : 
            is_correct_pick===0.5 ? 'yellow.500' : 
            is_correct_pick===0 ? 'red.300' : 
            'green.200'; 
    const pick_border_color = 
            is_correct_pick===1 ? 'green.700' : 
            is_correct_pick===0.5 ? 'yellow.700' : 
            is_correct_pick===0 ? 'red.600' : 
            'green.400';
    const gameScores = sh.calc_full_game_score(my_pick, game, my_config, state?.event?.nfl_picks, state?.games, is_game_scored ? null : 1)

    //Fix Wrong Pick is still green
    //Color Bonuses Appropriately

    const udog_color = gameScores.underdog_points > 0 ? fullBonusColor : bgContrastColor;
    const key_color = gameScores.key_points >= 10 ? fullBonusColor : gameScores.key_points > 0 ? halfBonusColor : bgContrastColor;
    const ftw_color = gameScores.ftw_points > 0 ? fullBonusColor : bgContrastColor;


    const handleSettingPick = (team_id) => {
        if (is_game_started) {
          return;
        }
        const existing_pick = picks.find((pick) => pick.pick === team_id);
    
        if (existing_pick) {
          removePick(team_id);
        } else if (!isPickDisabled) {
          const opponent = game.home_team === team_id ? game.away_team : game.home_team;
          removePick(opponent);
          addPick(team_id);
        }
      };
    
      const handleSettingKeyGame = (team_id) => {
        if (is_game_started) {
          return;
        }
    
        const existingPick = picks.find(pick => pick.pick === team_id);
        if (!existingPick) {
          // If the team isn't picked, we can't set it as a key game
          return;
        }
    
        toggleKeyGame(team_id);
      };
    
    return (
        <HStack 
        width="100%" maxWidth="800px" alignItems="stretch" p={2}
        borderRadius="1px solid" borderColor="forwardWhite.200" boxShadow="md" mb={1}
        bgColor={bgColor}
        flexDir={isMobile ? 'row' : 'column'}>
        <VStack bg={bgColor}>
            <HStack width="100%" borderBottom="1px solid" fontSize="xs">
                <Box flex="1">{dh.getWeekday(new Date(game.game_time))}</Box>
                <Box flex="1">{dh.formatUTCDate(new Date(game.game_time),'MMM d')}</Box>
                <Box flex="1">@ {dh.formatUTCDate(new Date(game.game_time),'h:mm a')}</Box>
            </HStack>
        <HStack width="100%" alignItems="stretch">
            <Flex flex="1" 
            boxSizing="border-box"
                    bg={is_away_pick ? pick_color : bgContrastColor}
                    border={is_away_key ? '4px solid' : is_away_pick ? '1px' : '0px'}
                    borderColor={pick_border_color}
                    isDisabled={is_game_started}
                    padding={2}
                    cursor={is_game_started ? 'not-allowed' : 'pointer'}
                    /*_hover={isMobile || is_game_started ? {} : {bg: is_home_pick ? pickColor : is_away_pick ? bgContrastColor : 'green.300'}}*/
                    _hover={{boxShadow: 'inset 0px 0px 0px 4px black'}}
                    boxShadow="md"
                    width="100%"
                    onClick={() => handleSettingPick(away_team.team_id)}
                     p={1}>
                <HStack justifyContent="space-between">
                <HStack>
                    <Image src={`/pickem/teams/${away_team.team_id.toLowerCase()}.png`} alt={away_team.team_name} maxWidth="40px" maxHeight="40px"  />
            <VStack alignItems="flex-start" spacing={0} flex="1" >
                <Text>{away_team.team_location}</Text>
                <Text fontSize="lg" fontWeight="bold">{away_team.team_name}</Text>
            </VStack>
            </HStack>
            <VStack>
                {is_game_scored && <Text fontWeight="bold">{game.away_points}</Text>}
            <Text fontStyle="oblique">{game.line > 0 ? '-'+game.line : '+'+(-1*game.line)}</Text>
            </VStack>
            </HStack>
            </Flex>
            <Text>at</Text>
            <Flex flex="1" 
                    boxSizing="border-box"
                    bg={is_home_pick ? pick_color : bgContrastColor} 
                    border={is_home_key ? '4px solid' : is_home_pick ? '1px' : '0px'} 
                    borderColor={pick_border_color}
                    isDisabled={is_game_started}
                    cursor={is_game_started ? 'not-allowed' : 'pointer'}
                    padding={2}
                    /*_hover={isMobile || is_game_started ? {} : {bg: is_away_pick ? pickColor : is_home_pick ? bgContrastColor : 'green.300'}}*/
                    _hover={{boxShadow: 'inset 0px 0px 0px 4px black'}}
                    boxShadow="md"
                    width="100%"
                    onClick={() => handleSettingPick(home_team.team_id)}
                    p={1}>
            <HStack justifyContent="space-between">
                <HStack>
                    <Image src={`/pickem/teams/${home_team.team_id.toLowerCase()}.png`} alt={home_team.team_name} maxWidth="40px" maxHeight="40px"  />
            <VStack spacing={0} alignItems="flex-start">
                <Text>{home_team.team_location}</Text>
                <Text fontSize="lg" fontWeight="bold">{home_team.team_name}</Text>
            </VStack>
            </HStack>
            <VStack>
                {is_game_scored && <Text fontWeight="bold">{game.home_points}</Text>}
            <Text fontStyle="oblique">{game.line > 0 ? '+'+game.line : (game.line)}</Text>
            </VStack>
            </HStack>
            </Flex>
        </HStack>
        </VStack>
        <Box flex="1">
            <Button 
                    colorScheme={my_pick?.is_key ? 'teal' : 'white'}
                    variant={my_pick?.is_key ? 'solid' : 'outline'}
                    size="md"
                    width="100%"
                    onClick={() => handleSettingKeyGame(is_home_pick ? game.home_team : game.away_team)}
          isDisabled={is_game_started || (!is_home_pick && !is_away_pick) || (isKeyGameDisabled && !(is_home_pick?.is_key || is_away_pick?.is_key))}
                    >{my_pick?.is_key ? 'Key' : 'Key'}</Button>

        </Box>
        <Box flex="1" bg={bgContrastColor}  p={1} textAlign="center">
            <Text>
                <HStack width="100%" spacing={0} fontSize="xs">
                    {gameScores.total_points >= 0 &&
                    <>
                    <VStack flex="1" textAlign="center" borderRight="1px solid" spacing={0}>
                    <Text>Base</Text>
                    <Text>{state?.event?.configuration?.scoring?.base*gameScores.correct_pick} pts</Text>
                    </VStack>
                    <VStack flex="1" textAlign="center" borderRight="1px solid" spacing={0} bg={udog_color}>
                    <Text>UDog</Text>
                    <Text>{gameScores.underdog_points} pts</Text>
                    </VStack>
                    <VStack flex="1" textAlign="center" borderRight="1px solid" spacing={0} bg={key_color}>
                    <Text>Key</Text>
                    <Text>{gameScores.key_points} pts</Text>
                    </VStack>
                    <VStack flex="1" textAlign="center" borderRight="1px solid" spacing={0} bg={ftw_color}>
                    <Text>FTW</Text>
                    <Text>{gameScores.ftw_points} pts</Text>
                    </VStack>
                    <VStack flex="1" textAlign="center" spacing={0}>
                    <Text fontWeight="bold">Total</Text>
                    <Text fontWeight="bold">{gameScores.total_points} pts</Text>
                    </VStack>
                    </> }


                </HStack>
            </Text>
        </Box>
        </HStack>
    )

}

export default GameRow