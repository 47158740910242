import React, { useContext, useState, useEffect } from 'react';
import { VStack, Heading, Text, Table, Thead, Tr, Th, Tbody, Td, HStack, Image, useMediaQuery, Link, Box, useColorModeValue } from '@chakra-ui/react';
import { PickemContext } from '../handlers/context/PickemContext';
import { FaCheckCircle } from 'react-icons/fa';
import sh from '../calc/scoring.js';
import dh from '../calc/dates.js';
import { Link as RouterLink } from 'react-router-dom';

const PlayerList = () => {
    const { state } = useContext(PickemContext);
    const [isMobile] = useMediaQuery('(max-width: 600px)');
    const [isWide] = useMediaQuery('(min-width: 1000px)');

    const [weekToShow, setWeekToShow] = useState(state?.current_week);
    const [seasonToShow, setSeasonToShow] = useState(state?.event?.season);
    const [weekData, setWeekData] = useState({
        players: [],
        picks: [],
        games: [],
        overall_stats: {},
        event_stats: {},
    });

    const bgColor = useColorModeValue('forwardWhite.300', 'forwardBlue.700');
    const pickColor = useColorModeValue('forwardGold.50', 'forwardGold.600');
    const correctPickColor = useColorModeValue('green.300', 'green.600');
    const incorrectPickColor = useColorModeValue('red.300', 'red.600');
    const tiePickColor = useColorModeValue('yellow.300', 'yellow.600');
    const naPickColor = useColorModeValue('gray.200','gray.700');

    const current_user = state?.players?.find((player) => player.user_id === state?.user?.id);
    console.log('STATS:')
    console.log(weekData);
    useEffect(() => {
        const fetchWeekData = () => {
            const filteredPicks = state?.event?.nfl_picks?.filter(
                (pick) => pick.season === seasonToShow && pick.week === weekToShow
            );

            const filteredGames = state?.games?.filter(
                (game) => game.season === seasonToShow && game.week === weekToShow
            );

            const players_with_week_stats = state?.event?.nfl_player_events?.map((player) => {
                const player_week_stats = state?.event_stats?.[player.player_id]?.weeklyStats?.[weekToShow] || {};
                return {
                    ...player,
                    ...player_week_stats,
                }

            });

            console.log(players_with_week_stats)


            setWeekData({
                players: players_with_week_stats || [],
                picks: filteredPicks || [],
                games: filteredGames || [],
                overall_stats: state?.overall_stats || {},
                event_stats: state?.event_stats || {},
            });
        };

        fetchWeekData();
    }, [weekToShow, seasonToShow, state]);

    const handleWeekChange = (direction) => {
        setWeekToShow((prevWeek) => {
            const newWeek = direction === 'next' ? prevWeek + 1 : prevWeek - 1;
            return Math.max(1, Math.min(newWeek, 22));
        });
    };

    const renderGamePick = (game, playerPicks, isPlayerRow, index, games) => {
        const pick = playerPicks.find(pick => pick?.pick === game?.home_team || pick?.pick === game?.away_team);
        const is_pick_correct = sh.calc_correct_picks(pick, game);
        const isDifferentTime = (new Date(game?.game_time) - new Date(weekData?.games?.[index - 1]?.game_time)) > 3600000;
    
        
        const pickColor = pick ? (is_pick_correct === 1 ? correctPickColor : is_pick_correct > 0 ? tiePickColor : incorrectPickColor) : naPickColor;
        //console.log(dh.convertESTtoUTC(game?.game_time), new Date())
        if (!pick && !isWide) return null;


        else if (pick && (dh.convertESTtoUTC(game?.game_time) < new Date() || pick?.player_id === current_user?.id)) {
        return (
            <VStack justifyContent="center" key={game.id} flex="1" 
            spacing={0} minWidth="30px" bgColor={pickColor} p={2} 
             boxShadow="md"
             borderLeft = {isDifferentTime ? '2px solid' : ''}
             borderColor = 'black'
            >
                {pick ? (
                    <>
                        <Image src={`/pickem/teams/${pick?.pick?.toLowerCase()}.png`} alt={pick?.pick} maxWidth="30px" maxHeight="30px" />
                        <Text fontSize="xs" fontWeight="bold">{pick?.is_key ? 'Key' : ''}</Text>
                        <Text fontSize="xs" fontWeight="bold">
                            {((pick?.pick === game?.away_team && game?.line < 0) ||
                            (pick?.pick === game?.home_team && game?.line > 0))
                            ? 'Udog' : ''}
                        </Text>
                    </>
                ) : (
                    <Text fontSize="xs">-</Text>
                )}
            </VStack>
        );
    }
    else {

        return (
            <VStack key={pick?.id} flex="1" spacing={0} minWidth="30px" bgColor={naPickColor} p={2} boxShadow="md"
            borderLeft = {isDifferentTime ? '2px solid' : ''}
             borderColor = 'black'>
                {dh.convertESTtoUTC(game?.game_time) < new Date() ? <Text>-</Text> : <Text fontSize="xs">TBD</Text>}
                {/*<Text fontSize="xs" fontWeight="bold">{pick?.is_key ? 'Key' : ''}</Text>*/}
            </VStack>
        );
    }
    };

    return (
        <VStack bg={bgColor} p={4} border="1px solid" borderColor="gray.500" boxShadow="md">
            <Heading as="h2" size="md">Current Players ({weekData.players.length}):</Heading>
            <HStack>
                <Box>
                    <Link onClick={() => handleWeekChange('prev')}>{'<'}</Link>
                </Box>
                <Heading as="h3" size="sm">{seasonToShow}: Week {weekToShow}</Heading>
                <Box>
                    <Link onClick={() => handleWeekChange('next')}>{'>'}</Link>
                </Box>
            </HStack>
            <Table variant="simple" size="sm">
                <Thead>
                    <Tr>
                        <Th>Player</Th>
                        <Th>Paid?</Th>
                        <Th>Made Picks?</Th>
                        {!isMobile && <Th>
                            <VStack spacing={0} width="100%">
                            <Text>Picks</Text>
                            <HStack width="100%" alignItems="stretch">
                                {isWide && weekData.games
                                    .sort((a, b) => new Date(a.game_time) - new Date(b.game_time))
                                    .map((game, index, games) => {
                                        //if a game is at a different time than the previous game, add a left border
                                        const isDifferentTime = (new Date(game?.game_time) - new Date(weekData?.games?.[index - 1]?.game_time)) > 3600000;
    
                                        return(
                                        <VStack key={game.id} flex="1" spacing={0} minWidth="30px" alignItems="center" borderLeft={isDifferentTime ? '2px solid' : ''}>
                                            <Text textAlign="center" fontSize="2xs">{dh.formatUTCDate(new Date(game.game_time), 'M/d')}</Text>
                                            <Text textAlign="center" fontSize="2xs">{dh.formatUTCDate(new Date(game.game_time), 'ha')}</Text>
                                            <Text fontSize="3xs">{game.away_team}</Text>
                                            <Text fontSize="3xs">@ {game.home_team}</Text>
                                        </VStack>
                                    )}
                                )}
                                    </HStack>
                            </VStack>
                            </Th>}
                        {!isMobile && <Th><VStack spacing={0}>
                            <Text>Score</Text>
                            <Text textColor="gray" fontSize="xs" fontStyle="oblique">SOV</Text>
                            </VStack></Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {weekData.players
                    .sort((a, b) => {
                        // Sort by total_points first
                        if (parseFloat(a.total_points) > parseFloat(b.total_points)) {
                          return -1;
                        } else if (parseFloat(a.total_points) < parseFloat(b.total_points)) {
                          return 1;
                        } else {
                          // If total_points are the same, sort by player_name
                          return a.nfl_players.player_name?.localeCompare(b.nfl_players.player_name);
                          //return 0;
                        }
                      })
                        .map((player, index) => {
                            console.log('PTS: ',player.total_points)
                            const player_picks = weekData.picks.filter((pick) => pick.player_id === player.player_id);
                            return (
                                <React.Fragment key={player.id}>
                                    <Tr>
                                        <Td>
                                            <Text fontWeight={current_user?.id === player.player_id ? 'bold' : 'initial'}>{index+1}. {player.nfl_players.player_name}</Text>
                                        </Td>
                                        <Td>{player?.has_paid ? <FaCheckCircle color="var(--chakra-colors-green-500)" /> : ''}</Td>
                                        <Td>{player_picks.length > 0 ? <FaCheckCircle color="var(--chakra-colors-green-500)" /> : ''}</Td>
                                        {!isMobile && (
                                            <>
                                                <Td>
                                                    <VStack spacing={1} width="100%">
                                                        <HStack alignItems="stretch" width="100%" spacing={1}>
                                                            {player_picks.length > 0 && weekData.games
                                                                .sort((a, b) => new Date(a.game_time) - new Date(b.game_time))
                                                                .map((game, index, games) => renderGamePick(game, player_picks, true, index, games))}
                                                        </HStack>
                                                        {current_user?.id === player.player_id && (
                                                            <Link fontWeight="bold" as={RouterLink} to={`/pickem/event/${state?.event?.id}/picks/${weekToShow}/player/${player.player_id}`}>
                                                                Edit/Make Picks
                                                            </Link>
                                                        )}
                                                        {player_picks.length === 0 && <Text color="gray.500" fontStyle="oblique">No picks yet!</Text>}
                                                    </VStack>
                                                </Td>
                                                <Td>
                                                    {/*player_picks.reduce((acc, pick) => {
                                                        const game = weekData.games.find(g => g.home_team === pick.pick || g.away_team === pick.pick);
                                                        if (game && (game.home_points || game.away_points)) {
                                                            const score = sh.calc_full_game_score(pick, game, state?.event?.configuration, weekData.picks, weekData.games);
                                                            return acc + score.total_points;
                                                        }
                                                        return acc;
                                                    }, 0)*/}
                                                    <Text>{player?.total_points?.toFixed(0) || '-'}</Text>
                                                    <Text fontSize="2xs" textColor="gray" fontStyle="oblique">{player?.sov || '-'}</Text>
                                                </Td>
                                            </>
                                        )}
                                    </Tr>
                                    {isMobile && (
                                        <Tr>
                                            <Td colSpan={3}>
                                                <VStack spacing={1}>
                                                    <HStack alignItems="stretch">
                                                        {weekData.games
                                                            .sort((a, b) => new Date(a.game_time) - new Date(b.game_time))
                                                            .map((game) => renderGamePick(game, player_picks, false))}
                                                    </HStack>
                                                    {current_user?.id === player.player_id && (
                                                        <Link fontWeight="bold" as={RouterLink} to={`/pickem/event/${state?.event?.id}/picks/${weekToShow}/player/${player.player_id}`}>
                                                            Edit/Make Picks
                                                        </Link>
                                                    )}
                                                    {player_picks.length === 0 && <Text fontStyle="oblique">No picks yet!</Text>}
                                                    <HStack spacing={1}>
                                                    <Text>{player?.total_points?.toFixed(0) || '-'} pts</Text>
                                                    <Text fontSize="2xs" textColor="gray" fontStyle="oblique">{player?.sov || '-'} sov</Text>
                                                    </HStack>
                                                </VStack>
                                            </Td>
                                        </Tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </Tbody>
            </Table>
        </VStack>
    );
}

export default PlayerList;